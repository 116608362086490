<template>
    <v-container class="ma-0 pa-0 pb-8">

        <div class="pt-6 px-8 pb-6">
            <v-row class="ma-0 mb-2">
                <strong>Filtros</strong>
                <v-spacer></v-spacer>
                <v-btn @click="removeFilter()" small class="elevation-0" style="border:solid 1px #dddddd;">
                    <v-icon small class="mr-1">mdi-filter-off</v-icon>Limpiar
                </v-btn>
                <v-btn class="hidden-md-and-up elevation-0 ml-4" @click="closeFilter()" small>
                    <v-icon class="mr-1">mdi-close</v-icon>
                </v-btn>
            </v-row>


            <v-menu offset-y :close-on-content-click="closeDatePicker(filters.created_between)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field  class="mt-6" clearable  v-model="filters.created_between" label="Fecha Creación" prepend-inner-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filters.created_between" range></v-date-picker>
            </v-menu>

            <v-autocomplete clearable @keydown.enter="filter()" v-model="filters.created_by_user_id" :items="userLists" item-text="name" item-value="id" attach chips label="Usuario" multiple>
                <template slot="no-data" class="pa-2">No existen usuarios relacionados.</template>  
            </v-autocomplete>

            <v-autocomplete clearable @keydown.enter="filter()" v-model="filters.type" :items="typeLists" item-text="text" item-value="value" attach chips label="Tipo de Movimiento" multiple>
                <template slot="no-data" class="pa-2">No existen movimientos relacionados.</template>  
            </v-autocomplete>

        </div>
        <v-card style="position:absolute; bottom:0; width:100%;" tile color="primary">
            <v-list-item link @click="filter()" dark>
                <v-list-item-content>
                    <strong>Filtrar (0)</strong>
                </v-list-item-content>
            </v-list-item>    
        </v-card>
    </v-container>
</template>
<script>
  export default {
    data: () => ({
        menuTo:false,
        menuFrom:false,
        filters:{
            created_between:undefined,
            created_by_user_id:undefined,
            type:undefined,
        },
    }),
    computed: {
        userLists(){
            return this.$store.state.user.users.map(id=>{
                return{
                    id:id.id,
                    name:id.name + ' ' + id.last
                }
            });
        }, 
        typeLists(){
            return [
                { text: 'Entrada Producción', value: 'Entrada Producción'}, 
                { text: 'Entrada Compra', value: 'Entrada Compra'}, 
                { text: 'Ajuste Manual', value: 'Ajuste Manual'}, 
                { text: 'Creación Producto', value: 'Creación de Producto'}, 
                { text: 'Ajuste IF', value: 'Ajuste Inventario Físico'}, 
                { text: 'Salida Producción', value: 'Salida Producción'}, 
                { text: 'Salida Venta', value: 'Salida Venta'}
            ]
        }
    },
    methods: {
        closeDatePicker(dates){
            if(dates!=undefined && dates.length==2){
                return true
            }else{
                return false
            }
        },
        filter(){
            this.$store.dispatch('inventory_movements/updateFilters', this.filters)
        },
        removeFilter(){
            this.$store.dispatch('inventory_movements/cleanFilter')
        },
        closeFilter(){
            this.$emit("closeFilter", false);
        },
    }
  }
</script>