<template>
    <v-container style="max-width:100vw;">
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="show_filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterMovements/>
        </v-navigation-drawer>
        <v-dialog v-model="filterMobile" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
            <filterMovements @closeFilter="closeFilter"/>
            </v-card>
        </v-dialog>
        <!-- Contenedor show-select -->
        <v-data-table 
            :options.sync="options" 
            :footer-props="{'items-per-page-options':[15, 30, 50, meta.total]}"
            :server-items-length="meta.total"
            :headers="headers" 
            :items="tableItems"
            :loading="loader"
            class="elevation-0 px-6 py-4">
            <template v-slot:[`item.type`]="{ item }">
                <v-list-item class="px-0" style="min-height:0px!important; font-size:14px;" :to="item.type.link!=''?{ path: item.type.link}:undefined">{{item.type.type}}</v-list-item>
            </template>
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat style="z-index:9!important;">
                    <v-toolbar-title>Movimientos de Inventario</v-toolbar-title>
                    <v-btn icon @click="openFilter()">
                        <v-badge overlap color="primary" :content="filtersLength" :value="filtersLength">
                            <v-icon>mdi-filter</v-icon>
                        </v-badge>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <itemsAutocomplete style="width:500px;"/>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import filterMovements from "../movements/filter.vue"
import itemsAutocomplete from "../movements/items_autocomplete.vue"
export default {
    components: {
        'filterMovements':filterMovements,
        'itemsAutocomplete':itemsAutocomplete
    },
    data:()=>({
        filterMobile:false,
        show_filters:false,
        options: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['inventories.created_at'],
            sortDesc: [true],
        },
    }),
    watch: {
        options: {
            handler() {
                this.fetchData()
            },
            deep: true,
        },
    },
    computed: {
        currentUser(){
            return this.$store.state.currentUser.user
        },
        headers(){
            return [
            { text: 'Tipo', value: 'type' },
            { text: 'Producto', value: 'items.name' },
            { text: 'Movimiento', value: 'quantity' },
            { text: 'Fecha', value: 'inventories.created_at' },
            { text: 'Usuario', value: 'created_by_user_id' },
            { text: 'Existencia', value: 'inventory' },
            { text: 'Costo', value: 'cost' },
        ]},
        tableItems(){
            return this.$store.state.inventory_movements.data.map(item=>{return{
                ...item,
                type:this.type(item.type, item.production_id, item.sale_id, item.shopping_id),
                cost:(item.unit_cost*1)?.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                'items.name':item.item_name,
                'inventories.created_at':item.created_at_formatted,
                created_by_user_id:item.created_by_name
            }})
        },
        meta(){
            return this.$store.state.inventory_movements.meta
        },
        filtersLength(){
            return this.$store.state.inventory_movements.filters_length;
        },
        loader(){
            return this.$store.state.inventory_movements.loader
        },
    },
    methods:{
        fetchData(){
            this.$store.dispatch('inventory_movements/getData', this.options) 
        },
        permissions(permission) {
            if (this.currentUser.id === 1) {
                return true
            }
            return this.currentUser?.permissions?.includes(permission) || false
        },
        openFilter(){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.filterMobile = true
            }else{
                this.show_filters?(this.$emit("closeDrawer", true), this.show_filters = false):(this.$emit("closeDrawer", false), this.show_filters = true)
            }
        },
        closeFilter(){
            this.filterMobile = false
        },
        type(type, production_id, sale_id, shopping_id){
            if(production_id!=null){
                return {
                    'type': type + ' ' + production_id,
                    'link': '/productions/'+ production_id
                }
            }else if(sale_id!=null){
                return {
                    'type': type + ' ' + sale_id,
                    'link': '/sales/'+ sale_id
                }
            }else if(shopping_id!=null&&shopping_id!=undefined&&shopping_id!=''){
                return {
                    'type': type + ' ' + shopping_id,
                    'link': '/shoppings/'+ shopping_id
                }
            }else{
                return {
                    'type': type,
                    'link': ''
                }
            }
        },
    },
    mounted() {
        this.fetchData()
    },
}
</script>
<style scoped>
.item-hover:hover{
    background: #00000008;
}
</style>
<style>
.v-data-table__progress th{
    display: table-cell;
    padding-bottom:5px!important;
}
</style>