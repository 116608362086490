<template>
    <div>
        <v-text-field clearable @click:clear="clearFilter" @click="showlist=true" outlined dense hide-details rounded v-model="searchItems" @input="debounceSearch" :loading="isLoadingItems" label="Producto" placeholder="Escribe para buscar"></v-text-field>
        <v-list v-if="searchItems!=''&&!isLoadingItems&&itemsList.length>0&&showlist" class="py-0" style="position: absolute; z-index: 999; height: 130px; overflow-y: scroll; box-shadow: 0px 0px 2px 0px #0000004a; cursor:pointer;">
            <v-list-item v-for="(p, index) in itemsList" :key="index" style="border-bottom:1px solid #00000021;" class="item-hover"  @click="selectProduct(p)">
                {{p.name}}
            </v-list-item>
        </v-list>
        <v-list v-else-if="searchItems!=''&&!isLoadingItems&&showlist" class="py-0" style="position: absolute; z-index: 999; height: 130px; box-shadow: 0px 0px 2px 0px #0000004a; cursor:pointer;">
            <v-list-item style="border-bottom:1px solid #00000021;" class="item-hover">
                No existen productos relacionados.
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import axios from "axios"
export default {
    data(){
        return{
            loadingItems: false,
            itemsList:[],
            searchItems:'',
            showlist:false,
            debounceSearch: null,
            isLoadingItems:false,
        }
    },
    created() {
        this.debounceSearch = debounce(this.searchData, 1000);
    },
    methods:{
        searchData(){
            if(this.searchItems!=null){
                this.isLoadingItems = true;
                const searchTerm = this.searchItems.trim();
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v2/item/pos?filter[pos]=' + this.searchItems + '&itemsPerPage=15')
                .then(response => {
                    if (this.searchItems === searchTerm) {
                        this.itemsList = response.data.data
                        this.isLoadingItems = false;
                        this.showlist = true
                    }
                })
            }
        },
        selectProduct(p){
            this.searchItems=p.name
            this.showlist=false
            this.$store.dispatch('inventory_movements/updateFilters', {item_id: p.id})
        },
        clearFilter(){
            this.showlist = false
            this.$store.dispatch('inventory_movements/updateFilters', {item_id: null})
        }
    }
}
</script>

<style>

</style>